// 统计部门酒店订单分析列表
import req_hotel_overproof_data from "@/lib/data-service/haolv-default/consumer_t_od_hotel_statement_queryHotelDeptAnalyze";
// 酒店报表部门列表
import req_departmentList from "@/lib/data-service/haolv-default/consumer_department_getDepartmentList";

export default {
  data() {
    return {
      loading: false,
      form: {
        deptId: [''],
        startDate: "",
        endDate: "",
      },
      dateRange: null,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      list: [],
      departList: [],
    };
  },
  components: {},
  created() {},
  mounted() {},
  activated() {
    this.init();
  },
  deactivated() {},
  destroyed() {},
  watch: {},
  computed: {},
  filters: {},
  methods: {
    req_data() {
      if (this.loading) return;
      this.loading = true;
      this.list = []
      let params = {...this.form};
      console.log(params)
      params.deptId = params.deptId.length ? params.deptId[params.deptId.length - 1] : '';
      req_hotel_overproof_data(params).then((res) => {
        this.list = res.hotelDeptAnalyzeResultList;
        this.loading = false;
      }).catch((err) => {
        this.loading = false;
      });
    },
    init() {
      this.departList = [];
      this.req_data();
      req_departmentList().then((res) => {
        let departList = res.datas.departmentListResults;
        departList.unshift({
          deptId: '',
          deptName: '全部'
        })
        this.departList = departList;
      });
    },
    //时间选择
    change_date(val) {
      const form = this.form;
      if (val) {
        form.startDate = val[0];
        form.endDate = val[1];
      } else {
        form.startDate = "";
        form.endDate = "";
      }
    },
  },
};
